.footer {    
    background: var(--back-color);
    color: var(--font-color);
    text-align: center;
    // padding-top: 20px;
    
    height: 40px;
    border-top: 1px solid;
}
.footer a:visited {
    color: var(--next-color);
}