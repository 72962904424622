.main{    
    overflow-y:auto;
    padding-top: 10px;
    padding-bottom: 10px;
    height: calc(100vh - 179px);

    display: flex;
    flex-direction: row;
    gap: 14px;
    flex-wrap: wrap;
    background: var(--back-color);
    justify-content: center;
    background: var(--back-color);
}