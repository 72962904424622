// .container {
//     width: 860px;
//     margin: 0 auto;
// }
.basket {
    height: 100vh;
    overflow-y: hidden;
    background-color: var(--back-color);

    &__header-container{
        // width: 1300px;
        margin: 0 auto;

        fill: var(--back-color);
        stroke: var(--font-color);
        transition: 0.7s;
    }

    &__inner-container{
        // width: 860px;
        margin: 0 auto;
    }

    &__header {
        display: flex;
        gap: 63px;
        justify-content: space-around;
        align-items: center;
        margin-left: 30px;
        margin-bottom: 30px;
        margin-top: 20px;

        // padding-top: 53px;

        // font-family: Montserrat;
        font-size: 31px;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
        color: var(--font-color);
    }

    &__footer {  // ******************
        height: 89px;
        border-top: 1px solid var(--font-color);
        padding-top: 20px;
        text-align: center;

    }
    
    &__footer-button {
        background: var(--font-color);
        color: var(--back-color);

        width: 200px;
        height: 42px;
        align-items: center;        

        // font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: center;
        cursor: pointer;
        // margin-left: auto;

        transition: 0.7s;
    }
    &__footer-button:hover {
        background: var(--back-color);
        color: var(--point-color);
        transition: 0.7s;
    }

    &__order-text {
        margin-right: 20px;
        color: var(--font-color);
        // font-family: Montserrat;
        font-size: 21px;
        font-weight: 400;
        line-height: 26px;
        text-transform: uppercase;
    }

    &__order-sum {
        color:var(--point-color);
        // font-family: Montserrat;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
    }
    &__main{
        height: calc(100vh - 211px);
        overflow-x: auto;
    }   

}

.basket__header svg {
    order:-1;
    fill: var(--font-color);
    stroke: var(--back-color);
    transition: 0.7s;
}
.basket__header svg:hover {
    fill: var(--back-color);
    stroke:var(--point-color);
    cursor: pointer;
    transition: 0.7s;
}

