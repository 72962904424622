.header {    
    background: var(--back-color);
    height: 115px;
    color: var(--font-color);

    padding-left: 70px;
    padding-right: 70px;
    align-items: center;

    display: flex;
    justify-content: space-between;

    border-bottom: 1px solid;

    &__basket {
        // font-family: Montserrat;
        font-size: 17px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0;
        text-align: right;
        // line-height: 2em;

        display: flex;
        align-items: center;
    }
    
    &__title {
        display: flex;
    }
    &__label {
        font-family: Oswald;
        color: var(--point-color);
        font-size: 26px;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 0.08em;
        padding-bottom: 6px;
    }
    
    &__backButton {
        margin-top: 3px;
        margin-right: 15px;
    }

}
.basket__values {
    margin-right: 20px;
    // padding-top: 4px;
}
.header__label a:visited {
    color: var(--font-color);
}

.header svg {
    fill: var(--font-color);
    stroke: var(--back-color);
    transition: 0.7s;
}
.header svg:hover {
    fill: var(--back-color);
    stroke: var(--point-color);
    cursor: pointer;
    transition: 0.7s;
}
@media (max-width:600px) {
    .header {
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
    }
}
