.details {
    background-color: var(--back-color);
    // overflow-y: auto;
    // width: 100vw;
    height: 100vh;
    // background: url('../../../public/images/detailsWallpaper.jpg') no-repeat center center fixed;
    // background-size: 3855px 1928px;  
    margin: 0 auto; 

    &__header {
        display: flex;
        gap: 13px;
        align-items: center;
        justify-content: flex-start;
        padding-left: 50px;
        padding-right: 50px;
        height: 113px;

        // padding-top: 53px;

        // font-family: Montserrat;
        font-size: 17px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: right;
        color: var(--font-color);
    }
    &__header svg{
        order:-1;
        fill: var(--font-color);
        stroke: var(--back-color);
        transition: 0.7s;
    }
    &__header svg:hover {
        fill: var(--back-color);
        stroke:var(--point-color);
        cursor: pointer;
        transition: 0.7s;
    }
    
    &__basket {
        // font-family: Montserrat;
        font-size: 17px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: right;

        display: flex;
    }
    &__values{
        margin-left: auto;
        padding-top: 4px;
        order:555;
    }
    &__basket-icon{
        order:557;
    }

    &__footer {
        height: 89px;
        border-top: 1px solid var(--point-color);

    }
    &__footer-container{
        display: flex;
        justify-content: space-between;        
        align-items: center;
    }

    &__order-button {
        background: var(--font-color);
        color: var(--back-color);

        width: 200px;
        height: 42px;
        align-items: center;

        // font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: center;
        cursor: pointer;
        margin-top: 3px;
        margin-right: 3px;

        transition: 0.7s;
    }
    &__order-button:hover {
        background: var(--back-color);
        color:var(--point-color);
        transition: 0.7s;
    }
    
    &__main{
        // height: calc(100vh - 181px);
        display: flex;
        flex-direction: row;   
        // padding: 30px;           
    }

    &__inner{
        display: flex;
        flex-direction: column;
        margin-top: 60px;        
        margin-left: 30px;
        
    }

    &__title{
        // font-family: Montserrat;
        font-size: 30px;
        font-weight: 500;
        line-height: 37px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 21px;

        color:var(--font-color);
    }

    &__desc{
        // font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 45px;

        color: var(--font-color);
    }

    &__img{
        // width: 501px;
        // height: 400px;
        // margin-right: 199px;
    }

    &__price {
        color:var(--font-color);
        // font-family: Montserrat;
        font-size: 17px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
    }
    &__price-input {
        width: 180px;
        margin-right: 10px;
    }

    &__weight {
        color:var(--font-color);
        // font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
    
        align-self: end;
    }
    &__count {
        margin-bottom: 30px;
    }
    &__buttons {
        display: flex;
        flex-direction: column;
    }
}
@media (max-width: 860px) {
    .details {
        &__main {
            flex-direction: column;
        }
        &__inner {
            margin-top: 0px;
            align-items: center;
            margin-bottom: 30px;
        }
    }
    
}
img {
    max-width: 100%;
    scale: 0.8;
}