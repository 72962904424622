:root {
    --back-color: #453C67;
    --next-color: #6D67E4;
    --font-color: #46C2CB;
    --point-color: #F2F7A1;    
    font-family: Helvetica;
    background-color: aqua;
    // min-width: 600px;
}

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

@font-face {
    font-family: "Oswald"; 
    src: url('../../public/fonts/Oswald-Regular.ttf');
} 
@font-face {
    font-family: "Helvetica"; 
    src: url('../../public/fonts/helvetica_light.otf');
} 


.button {
    width: 200px;
    height: 42px;
    // color: #D58C51;
    // background-color: #161516;
    border: 1px;
    border-color: var(--font-color);
    border-style: solid;
    cursor: pointer;

    font-family: Helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;  
}

