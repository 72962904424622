.card {
    text-decoration: none;
    width: 350px;
    height: 450px;
    
    border: 1px solid #46C2CB;
    
    padding-left: 21px;
    padding-right: 21px;
    color: var(--font-color);
    background: var(--back-color);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-decoration: none;

    &__title {
        // max-width: 300px;
        // font-family: Montserrat;
        font-size: 30px;
        // font-weight: 400;
        line-height: 47px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 9px;
        margin-top: 30px;

    }

    &__desc {
        text-align: center;
    }

    &__footer {
        padding-bottom: 10px;
        padding-top: 10px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__price {
        // font-family: Montserrat;
        font-size: 17px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
    }

    &__weight {
        // font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
    
        align-self: end;
    }

    &__item-count{
        width: 30px;
    }
    &__order-section{
        display: flex;
        align-items: center;
        width: 100px;
        justify-content: space-between;
    }
    &__count{
        margin-left: 9px;
        margin-right: 9px;
        // font-family: Montserrat;
        font-size: 17px;
        font-weight: 500;
        line-height: 21px;       
        
    }

    &__picture img{
        width: 300px;
        scale: 0.7;
        transition: 0.7s;
        margin-top: 15px;
    }
    
    &__picture img:hover{
        scale: 0.9;
        transition: 0.7s;
    }

    &__picture{
        display: flex;
        justify-content: center;
        // transition: 0.7s;
    }
        
    &__add {
        fill: var(--back-color);
        stroke: var(--font-color);
        transition: 0.7s;
    }


    &__add:hover{
        fill: var(--back-color);
        stroke:var(--point-color);
        cursor: pointer;
        transition: 0.7s;
    }
        
   
    &:hover{
        border-color: var(--point-color);
        transition: all 0.4s;
    }
}

