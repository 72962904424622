.cardForBasket {
    color: var(--font-color);
    background: var(--back-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;

    margin-left: 30px;
    margin-right: 30px;
    margin-top: 20px;
    
    &__title {
        // font-family: Montserrat;
        font-size: 17px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0 10px;

    }

    &__price{
        // font-family: Montserrat;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: auto;
        margin-right: 10px;
        
    }
    &__inner{
        display: flex;
        align-items: center;
        flex-flow: row wrap;
    }
    &__img{
        
        height: 100px;
        margin: 0 10px;

    }
    
    &__remove svg{
        fill: var(--font-color);
        stroke: var(--back-color);
        transition: 0.7s;
    }
    
    &__remove svg:hover{
        fill: var(--back-color);
        stroke:var(--point-color);
        cursor: pointer;
        transition: 0.7s;
    }
}